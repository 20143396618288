import Article from './article';

export default class Headless {
  headless = {};

  articles = [];

  isError = false;

  constructor(data = {}) {
    if (!data || !Object.keys(data).length || !data.elements) {
      this.isError = true;
    } else {
      this.headless = data;
      this.articles = this.headless.elements.map(item => new Article(item));
    }
  }

  get title() {
    return this.headless.title;
  }

  get url() {
    return this.headless.url;
  }

  get isFull() {
    return this.headless && Object.keys(this.headless).length;
  }
};