export default class Article {
  content = {};

  constructor(data = {}) {
    this.content = data;
  }

  get image() {
    return this.content.image;
  }

  get gallery() {
    return this.content.gallery;
  }

  get text() {
    return this.content.article;
  }

  get isFull() {
    return this.content && Object.keys(this.content).length;
  }
};