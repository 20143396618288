import { render, staticRenderFns } from "./photo-slider.vue?vue&type=template&id=7ea033f9"
import script from "./photo-slider.vue?vue&type=script&lang=js"
export * from "./photo-slider.vue?vue&type=script&lang=js"
import style0 from "./photo-slider.vue?vue&type=style&index=0&id=7ea033f9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconArrowThin: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-arrow-thin/index.vue').default})
