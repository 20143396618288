import { render, staticRenderFns } from "./images-grid.vue?vue&type=template&id=0ca685f2"
import script from "./images-grid.vue?vue&type=script&lang=js"
export * from "./images-grid.vue?vue&type=script&lang=js"
import style0 from "./images-grid.vue?vue&type=style&index=0&id=0ca685f2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzPaginationButton: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/pagination-button/index.vue').default,RzPreloader: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/preloader/index.vue').default,PhotoSlider: require('/opt/razlet.kg/src/components/photo-slider.vue').default,RzModal: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/modal/index.vue').default})
