
import PhotoSlider from '~/components/photo-slider';

export default {
  name: 'images-grid',
  components: {
    PhotoSlider,
  },
  props: { 
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isFull: false,
      selectedIndex: null,
      width: null,
      screen: null,
      imageSize: null,
      imageLoading: false,
      imagesCount: null,
      countPerRow: null,
      screens: {
        sm: {
          size: [0, 992],
          count: 2,
        },
        md: {
          size: [993, 1199],
          count: 4,
        },
        lg: {
          size: [1200, 5000],
          count: 5,
        },
      },
    };
  },
  computed: {
    showMoreCount() {
      return this.images.length - this.imagesCount > this.screens[this.screen].count ? this.screens[this.screen].count : this.images.length - this.imagesCount;
    },
    filteredImages() {
      return this.images.slice(0, this.imagesCount).map(img => {
        return {
          original: img.original,
          preview: this.imageSize > 300 ? img.md : img.sm,
        };
      });
    },
    gridSizes() {
      if (!this.imageSize) return {};
      return { gridTemplateColumns: `repeat(auto-fit, ${this.imageSize}px)`, gridTemplateRows: `repeat(auto-fit, ${this.imageSize}px)` };
    },
    pixelsLeft() {
      return this.width % this.countPerRow;
    },
  },
  created() {
    this.generatePhotoSizes();
  },
  mounted() {
    this.generatePhotoSizes();
  },
  methods: {
    generatePhotoSizes() {
      // eslint-disable-next-line
      this.width = this.$el ? this.$el.clientWidth : this.isMobile ? 300 : 1200;
      Object.keys(this.screens).forEach(key => {
        const scr = this.screens[key];
        if (this.width <= scr.size[1] && this.width >= scr.size[0]) {
          this.screen = key;
          this.imagesCount = this.images.length >= this.screens[this.screen].count ? this.screens[this.screen].count : this.images.length;
          this.countPerRow = this.imagesCount;
          this.imageSize = Math.floor(this.width / this.imagesCount);
        }
      });
    },
    openFull(index) {
      this.selectedIndex = index;
      this.imageLoading = true;
      this.isFull = true;
      setTimeout(() => {
        this.imageLoading = false;
      }, 10000);
    },
    closeFull() {
      this.imageLoading = false;
      this.isFull = false;
      this.selectedIndex = null;
    },
    showMore() {
      this.imagesCount += this.showMoreCount;
    },
    createImageSizes(index) {
      if (!this.imageSize) return {};
      const indexesToAdapt = [];
      this.images.forEach((img, i) => {
        if ((i + 1) % this.countPerRow === 0) indexesToAdapt.push(i);
      });
      const width = this.pixelsLeft && indexesToAdapt.includes(index) ? this.imageSize + this.pixelsLeft : this.imageSize;
      return { width: `${width}px`, height: `${this.imageSize}px` };
    },
  },
};
