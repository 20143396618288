import { fillSeo, getSuluPage } from '~/utils/sulu';

export default {
  async asyncData ({ store, route, error, redirect }) {
    const result = await getSuluPage({ store, route, error, redirect });
    return { ...result };
  },
  data () {
    return {
      seo: {},
      template: null,
      content: {},
    };
  },
  head() {
    return fillSeo(this.seo);
  },
};