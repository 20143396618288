
import ImagesGrid from '~/components/images-grid';

export default {
  name: 'static',
  components: {
    ImagesGrid,
  },
  props: { 
    content: {
      type: Object,
      required: true,
    },
    isSubPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formatToGrid(images) {
      return images.map(img => {
        return {
          original: this.replaceFormat(img.url),
          sm: this.replaceFormat(img.formatUri, 300),
          md: this.replaceFormat(img.formatUri, 600),
        };
      });
    },
    replaceFormat(str, format = null) {
      return str.replace('{format}', `${format}x${format}`).replace('.jpg', '.webp');
    },
  },
};
