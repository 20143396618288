

export default {
  name: 'photo-slider',
  props: {
    images: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      default: 0,
    },
    buttonsInside: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  created() {
    this.selectedIndex = this.selected;
  },
  mounted() {
    const sliderEl = this.$refs.slider;
    const prevEl = this.$refs.prev;
    const nextEl = this.$refs.next;

    const allowedForSwipeElements = [sliderEl, prevEl, nextEl];
    const self = this;
    document.addEventListener('swiped-right', function(e) {
      if (allowedForSwipeElements.includes(e.target)) {
        self.prevImage();
      }
    });
    document.addEventListener('swiped-left', function(e) {
      if (allowedForSwipeElements.includes(e.target)) {
        self.nextImage();
      }
    });
  },
  methods: {
    nextImage() {
      this.selectedIndex = this.selectedIndex === this.images.length - 1 ? 0 : this.selectedIndex + 1;
    },
    prevImage() {
      this.selectedIndex = this.selectedIndex === 0 ? this.images.length - 1 : this.selectedIndex - 1;
    },
  },
};
